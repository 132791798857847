import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Build up the whole picture',
    //paragraph: 'No matter what the area is, we are here to support your business. We are expertise in Global & Domestic Reselling, Import/export, IT Solutions, Consulting, BPO/Customer Support Services, Software development/testing, Cloud solutions, Recruiting , Staffing & Outsourcing'
    paragraph: 'No matter what the area is, we are here to support your business. We are expertise in Global & Domestic Reselling, Retail/wholesale, Import/export, BPO/Customer Support Services'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Global & Domestic Reselling, Import/export 
                    </h4>
                  <p className="m-0 text-sm" align="justify">
                      Expertise on global & domestic B2B Re-Sales,sales negotiations,Qoute & Pricing, Import/Export, etc. We do import/export various items based on the client need.  We buy items from manufators/suppliers in bulk and resell to the domestic & global clients. 


                    </p>
                </div>
              </div>
            </div>

           

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    BPO/Customer Support Services
                    </h4>
                  <p className="m-0 text-sm" align="justify">
              24/7 support for all prospective and existing clients via phone, email, chat & social media. Customer Service is the frontline of any organization and for us, customer is always the “KING”.

                    </p>
                </div>
              </div>
            </div>

          

          

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
